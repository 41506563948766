<template>
  <div>
<template>
      <v-select
        :items="countries"
        v-model="countryCode"
        :item-text="countryNamed"
        item-value="countryCode"
        label="Country"
        :loading="countryApi.isLoading"
      >
        <template v-slot:selection="{ item }">
          <v-img
            contain
            max-width="30"
            max-height="40"
            class="mr-2"
            :src="require('@/assets/Flag/' + item.countryCode + '.svg')"
          ></v-img>  - {{ item.name }}
        </template>
      </v-select>
   </template>
   <template>
      <FormInput
        :prefix="
          selectedCountry && selectedCountry.phoneCode
            ? selectedCountry.phoneCode
            : null
        "
        :value="mobile"
        :label="label"
        :rules="rules"
        type="text"
        @input="mobile = $event"
      ></FormInput>
  </template>
</div>
</template>

<script>
import FormInput from "@/components/Moderator/Account/FormInput.vue";
export default {
  components: {
    FormInput
  },
  computed:{
  
  },
  props: [
    "rules",
    "label",
    "value",
    "code"
  ],
  data: () => ({
    selectedCountry: null,
    mobile: null,
    countries: [],
    countryCode: "MYS",
    //BOC:[api]
    countryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.countryApi.method = "GET";
    this.countryApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/country/";

    this.countryApi.callbackReset = () => {
      this.countryApi.isLoading = true;
      this.countryApi.isError = false;
    };

    this.countryApi.callbackError = (e) => {
      this.countryApi.isLoading = false;
      this.countryApi.isError = true;
      this.countryApi.error = e;
    };
    this.countryApi.callbackSuccess = (resp) => {
      this.countryApi.isLoading = false;
      this.countries = resp;
      if(this.code){
       this.countryCode = this.$_.clone(this.code)
      }
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
      this.mobile = this.value.replace(this.selectedCountry.phoneCode,'');
      this.$emit("input",this.selectedCountry.phoneCode + this.mobile);
      this.$emit("getCode",this.selectedCountry.countryCode);
    };
  },
  mounted() {
    this.$api.fetch(this.countryApi);
  },
  watch:{
    countryCode: function (val) {
      this.countryCode = val;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
      this.$emit("input",this.selectedCountry.phoneCode + this.mobile);
      this.$emit("getCode",this.selectedCountry.countryCode);
    },
    mobile: function () {
      this.mobileRules = [];
        this.$emit("input",this.selectedCountry.phoneCode + this.mobile);
    },
  },
  methods: {
    countryNamed: (item) => `(${item.phoneCode}) ${item.name}`,
  },
};
</script>